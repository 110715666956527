import { apiGet, apiPost } from 'src/shared/utils/api-request';

export const getPolicyMasterDataAPI = async () => {
  const res = await apiGet(`policies/master`);
  return res;
};

export const getRolesIdentityAPI = async (data: any) => {
  const res = await apiPost('identity/roles', data);
  return res;
};

export const getUsersIdentityAPI = async (data: any) => {
  const res = await apiPost('identity/users', data);
  return res;
};

export const getNonHumanIdentityAPI = async (data: any) => {
  const res = await apiPost('identity/access-keys', data);
  return res;
};
