import Chart from 'react-apexcharts';
import type { ApexOptions } from 'apexcharts';
import { useEffect, useState } from 'react';
import { useTheme } from '@mui/material';
import { geRiskScoreColorAndLabel } from 'src/shared/utils/common-functions';

export const RiskScoreChart = ({ riskScore }) => {
  const theme = useTheme();
  const barChartptions: ApexOptions = {
    chart: {
      type: 'radialBar',
      offsetY: -20
    },
    stroke: {
      lineCap: 'round'
    },
    tooltip: {
      enabled: false
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 5,
          size: '45%',
          background: theme.palette.background.paper
        },
        track: {
          show: true,
          startAngle: undefined,
          endAngle: undefined,
          background: '#595E6B',
          opacity: 0.3,
          margin: 7,
          dropShadow: {
            enabled: false,
            top: 0,
            left: 0,
            blur: 3,
            opacity: 0
          }
        },
        dataLabels: {
          show: true,

          value: {
            show: false
          },
          total: {
            show: true,
            label: 'HIGH',
            color: '#fff',
            fontSize: '14px',
            fontWeight: 500
          }
        }
      }
    },

    labels: ['Risk Score'],
    colors: ['#FF0000', 'rgba(255, 0, 0, 0.31)']
  };

  const [chartOptions, setChartOptions] = useState(barChartptions);
  const [series, setSeries] = useState([44, 55, 67, 83]);

  useEffect(() => {
    const { chartColor, chartLabel } = geRiskScoreColorAndLabel(riskScore);
    const options = { ...chartOptions };
    options.colors = chartColor;
    options.plotOptions.radialBar.dataLabels.total.label = chartLabel;
    setSeries([100, 100]);
    setChartOptions(options);
  }, [riskScore]);

  return (
    <Chart
      height={220}
      width={200}
      options={chartOptions}
      series={series}
      type="radialBar"
    />
  );
};
