import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  alertStatusChangeAPI,
  CloseAlertsAPI,
  createJiraTicketApi,
  getAlertDetailsAPI,
  getAwsAlertsAPI,
  getPlaneCollectionAPI,
  investigateAlertDetailsAPI,
  RemediateAlertsAPI
} from '../apis/AwsAlertsApi';
import {
  showErrorMessage,
  showSuccessMessage
} from '../../../shared/components/toaster/index';
import dayjs from 'dayjs';
import {
  INPUT_DATE_TIME_FORMAT,
  RAW_DATA_PAGINATION_LIMIT
} from 'src/shared/constants/constants';

interface LastEvaluatedKey {
  pk?: string;
  sk?: string;
}

interface TransactionState {
  awsAlerts: string[];
  alertDetails: {};
  filteredAwsAlerts: string[];
  loadingAwsAlerts: boolean;
  loadingAlertDetails: boolean;
  currentPage: number;
  totalCount: number;
  lastEvaluatedKey: LastEvaluatedKey;
  filteredAlertLastEvaluatedKey: LastEvaluatedKey;
  AlertDialogData: {};
  openAlertDialog: boolean;
  investigatedAlertDetails: [];
  loadingInvestigatedAlertDetails: boolean;
  retry: boolean;
  investigatedAlertDetailsAPIPayload: {};
  breezIdentityType: string;
  anomalousIdentityData: any;
  planeCollectionLoading: boolean;
  planeCollection: any[];
  selectedPlanesData: any[];
}
const initialState: TransactionState = {
  awsAlerts: [],
  alertDetails: {},
  filteredAwsAlerts: [],
  loadingAwsAlerts: false,
  loadingAlertDetails: false,
  lastEvaluatedKey: null,
  filteredAlertLastEvaluatedKey: null,
  currentPage: 0,
  totalCount: 0,
  AlertDialogData: {},
  openAlertDialog: false,
  investigatedAlertDetails: [],
  loadingInvestigatedAlertDetails: false,
  retry: false,
  investigatedAlertDetailsAPIPayload: {},
  breezIdentityType: 'human',
  anomalousIdentityData: {},
  planeCollectionLoading: false,
  planeCollection: [] as any[],
  selectedPlanesData: [] as any[]
};

export const getPlaneCollection = createAsyncThunk(
  'awsAlerts/getPlaneCollection',
  async () => {
    const response = await getPlaneCollectionAPI();
    return response.data;
  }
);

export const getAwsAlerts = createAsyncThunk(
  'awsAlerts/getAwsAlerts',
  async (data: any) => {
    const response = await getAwsAlertsAPI(data);
    return response.data;
  }
);

export const getAlertDetails = createAsyncThunk(
  'awsAlerts/getAlertDetails',
  async (data: any) => {
    const response = await getAlertDetailsAPI(data);
    return response.data;
  }
);

export const getFilteredAwsAlerts = createAsyncThunk(
  'awsAlerts/getFilteredAwsAlerts',
  async (data: any) => {
    const response = await getAwsAlertsAPI(data);
    return response.data;
  }
);

export const alertStatusChange = createAsyncThunk(
  'awsAlerts/alertStatusChange',
  async (req: any, { rejectWithValue }) => {
    try {
      const response = await alertStatusChangeAPI(req.alert_id, req?.status);
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const remediateAlert = createAsyncThunk(
  'awsAlerts/remediateAlert',
  async (req: any, { rejectWithValue }) => {
    try {
      const response = await RemediateAlertsAPI(req.alert_id);
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const closeAlert = createAsyncThunk(
  'awsAlerts/closeAlert',
  async (req: any, { rejectWithValue }) => {
    try {
      const response = await CloseAlertsAPI(req.alert_id);
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const investigateAlertDetails = createAsyncThunk(
  'awsAlerts/investigateAlertDetails',
  async (input: any, { dispatch }) => {
    let { data, retryAttempt, clearState = false } = input;
    if (clearState) {
      dispatch(clearInvestigatedAlertDetailsState());
    }
    const response = await investigateAlertDetailsAPI(data);
    if (response?.data?.query_execution_id && retryAttempt <= 5) {
      retryAttempt += 1;
      dispatch(setRetry(true));
      setTimeout(
        () =>
          dispatch(
            investigateAlertDetails({
              data: {
                query_execution_id: response?.data?.query_execution_id,
                limit: RAW_DATA_PAGINATION_LIMIT
              },
              retryAttempt
            })
          ),
        2 ** retryAttempt * 1000
      );
    } else {
      dispatch(setRetry(false));
      return response?.data?.items ? response?.data : { items: [] };
    }
  }
);

export const createJiraTicket = createAsyncThunk(
  'awsAlerts/createJiraTicket',
  async (data: any) => {
    const response = await createJiraTicketApi(data);
    return response.data;
  }
);

export const awsAlertsSlice = createSlice({
  name: 'awsAlerts',
  initialState,
  reducers: {
    setCurrentPage: (state: any, action: PayloadAction<any>) => {
      state.currentPage = action.payload;
    },
    setOpenAlertDialog: (state: any, action: PayloadAction<any>) => {
      state.openAlertDialog = action.payload;
    },
    setAlertDialogData: (state: any, action: PayloadAction<any>) => {
      state.AlertDialogData = action.payload;
    },
    ClearFilteredData: (state: any) => {
      state.filteredAwsAlerts[state.currentPage] = [];
      state.filteredAlertLastEvaluatedKey = null;
    },
    clearData: (state: any) => {
      state.awsAlerts = [];
      state.filteredAwsAlerts = [];
      state.lastEvaluatedKey = null;
      state.filteredAlertLastEvaluatedKey = null;
      state.currentPage = 0;
      state.filter = '';
      state.totalCount = 0;
    },
    clearInvestigatedAlertDetailsState: (state: any) => {
      state.investigatedAlertDetails = [];
    },
    setRetry: (state, action) => {
      state.retry = action.payload;
    },
    clearAlertDetails: (state: any) => {
      state.alertDetails = {};
    },
    setInvestigatedAlertDetailsPayload: (state, action) => {
      state.investigatedAlertDetailsAPIPayload = action.payload;
    },
    setBreezIdentityType: (state, action) => {
      state.breezIdentityType = action.payload;
    },
    setAnomalousIdentityData: (state, action) => {
      state.anomalousIdentityData = action.payload;
    },
    setSelectedPlanesData: (state, action) => {
      state.selectedPlanesData = action.payload;
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(getAwsAlerts.pending, (state) => {
        state.loadingAwsAlerts = true;
      })
      .addCase(
        getAwsAlerts.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loadingAwsAlerts = false;

          if (action.payload) {
            state.filteredAwsAlerts = {};
            state.filteredAlertLastEvaluatedKey = null;
            let data = action.payload.items;
            data = data.map((d, index) => {
              return { id: index, ...d };
            });
            state.awsAlerts = data;
            if (action.payload.last_evaluated_key) {
              state.lastEvaluatedKey = action.payload.last_evaluated_key;
            }
            state.totalCount = action.payload.total_count;
          }
        }
      )
      .addCase(getAwsAlerts.rejected, (state) => {
        state.loadingAwsAlerts = false;
      })
      .addCase(getFilteredAwsAlerts.pending, (state) => {
        state.loadingAwsAlerts = true;
      })
      .addCase(
        getFilteredAwsAlerts.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loadingAwsAlerts = false;

          if (action.payload) {
            state.awsAlerts = {};
            state.lastEvaluatedKey = null;
            let data = action.payload.items;
            data = data.map((d, index) => {
              return { id: index, ...d };
            });
            state.filteredAwsAlerts = data;
            if (action.payload.last_evaluated_key) {
              state.filteredAlertLastEvaluatedKey =
                action.payload.last_evaluated_key;
            }

            state.totalCount = action.payload.total_count;
          }
        }
      )
      .addCase(getFilteredAwsAlerts.rejected, (state) => {
        state.loadingAwsAlerts = false;
      })
      .addCase(alertStatusChange.pending, (state) => {
        state.loadingAwsAlerts = true;
      })
      .addCase(alertStatusChange.fulfilled, (state: any, action: any) => {
        state.loadingAwsAlerts = false;
        if (action.payload.status === 200) {
          state.awsAlerts = {};
          state.lastEvaluatedKey = null;
          state.filteredAwsAlerts = {};
          state.filteredAlertLastEvaluatedKey = null;
          showSuccessMessage(action.payload?.data?.details);
        }
      })
      .addCase(alertStatusChange.rejected, (state, action: any) => {
        state.loadingAwsAlerts = false;
        showErrorMessage(action.payload?.error_details);
      })

      .addCase(remediateAlert.pending, (state) => {
        state.loadingAwsAlerts = true;
      })
      .addCase(remediateAlert.fulfilled, (state: any, action) => {
        state.loadingAwsAlerts = false;
        if (action.payload.status === 200) {
          state.awsAlerts = {};
          state.lastEvaluatedKey = null;
          state.filteredAwsAlerts = {};
          state.filteredAlertLastEvaluatedKey = null;
          showSuccessMessage('Remediation Action Generated successfully');
        }
      })
      .addCase(remediateAlert.rejected, (state, action: any) => {
        state.loadingAwsAlerts = false;
        showErrorMessage(action.payload?.error_details);
      })

      .addCase(closeAlert.pending, (state) => {
        state.loadingAwsAlerts = true;
      })
      .addCase(closeAlert.fulfilled, (state: any, action) => {
        state.loadingAwsAlerts = false;
        if (action.payload.status === 200) {
          state.awsAlerts = {};
          state.lastEvaluatedKey = null;
          state.filteredAwsAlerts = {};
          state.filteredAlertLastEvaluatedKey = null;
          showSuccessMessage('Alert closed successfully');
        }
      })
      .addCase(closeAlert.rejected, (state, action: any) => {
        state.loadingAwsAlerts = false;
        showErrorMessage(action.payload?.error_details);
      })
      .addCase(investigateAlertDetails.pending, (state) => {
        state.loadingInvestigatedAlertDetails = true;
      })
      .addCase(
        investigateAlertDetails.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loadingInvestigatedAlertDetails = false;
          if (action.payload) {
            state.investigatedAlertDetails = setRawData(
              action,
              state,
              'investigatedAlertDetails'
            );
          }
        }
      )
      .addCase(investigateAlertDetails.rejected, (state) => {
        state.loadingInvestigatedAlertDetails = false;
        state.investigatedAlertDetails = [];
      })
      .addCase(getAlertDetails.pending, (state) => {
        state.loadingAlertDetails = true;
      })
      .addCase(
        getAlertDetails.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loadingAlertDetails = false;
          if (action.payload) {
            state.alertDetails = action.payload;
          }
        }
      )
      .addCase(getAlertDetails.rejected, (state) => {
        state.loadingAlertDetails = false;
      })
      .addCase(createJiraTicket.pending, (state) => {
        state.loadingAwsAlerts = true;
      })
      .addCase(
        createJiraTicket.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loadingAwsAlerts = false;
          if (action.payload) {
            showSuccessMessage('JIRA ticket created successfully');
          }
        }
      )
      .addCase(createJiraTicket.rejected, (state) => {
        state.loadingAwsAlerts = false;
        showErrorMessage('Error in creating JIRA ticket');
      })
      .addCase(getPlaneCollection.pending, (state) => {
        state.planeCollectionLoading = true;
      })
      .addCase(
        getPlaneCollection.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.planeCollectionLoading = false;
          if (action.payload) {
            state.planeCollection = action.payload;
          }
        }
      )
      .addCase(getPlaneCollection.rejected, (state) => {
        state.planeCollectionLoading = false;
      });
  }
});

const setRawData = (action, state, rawDataStateKey) => {
  const prevState = state?.[rawDataStateKey]?.['data'];

  const sortedData = sortRawData(
    prevState
      ? [...prevState, ...action?.payload?.items]
      : action?.payload?.items
  );
  return {
    data: sortedData,
    lastEvaluatedKey: action?.payload?.last_evaluated_key,
    count: sortedData?.count,
    totalCount: action?.payload?.total_count
  };
};

const sortRawData = (dataList) => {
  let sortedData = dataList?.sort(function (a, b) {
    return (
      dayjs(b.breez_timestamp, INPUT_DATE_TIME_FORMAT).date() -
      dayjs(a.breez_timestamp, INPUT_DATE_TIME_FORMAT).date()
    );
  });
  sortedData = sortedData?.map((d) => {
    if (d.breez_identity_type === 'machine') {
      return {
        ...d,
        principal_type: d?.principal_arn,
        principal_arn: d?.session_name
      };
    } else {
      return d;
    }
  });
  return sortedData;
};
export const {
  setCurrentPage,
  setOpenAlertDialog,
  setAlertDialogData,
  ClearFilteredData,
  clearData,
  clearInvestigatedAlertDetailsState,
  setRetry,
  clearAlertDetails,
  setInvestigatedAlertDetailsPayload,
  setBreezIdentityType,
  setAnomalousIdentityData,
  setSelectedPlanesData
} = awsAlertsSlice.actions;
export default awsAlertsSlice.reducer;
